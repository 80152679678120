
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { EXCEPTION_TYPE } from "@/core/Constant";
import { ResetPasswordModel } from "@/domain/authentication/resetPassword/model/ResetPasswordModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { CUSTOM_ERROR_MESSAGES, ROUTE_PAGES, SWAL_MESSAGES } from "@/domain/Constant";

export default defineComponent({
  name: "password-reset",
  props: {
    token: {
      type: String,
      required: true
    }
  },
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const swalNotification = new SwalNotification();
    const submitButton = ref<HTMLElement | null>(null);
      let token : undefined | string = undefined;

    //Create form validation object
    const forgotPassword = Yup.object().shape({
  password: Yup.string()
    .min(
      EXCEPTION_TYPE.MIN_PASSWORD_LENGTH,
      t(CUSTOM_ERROR_MESSAGES.password_min)
    )
    .required(t(CUSTOM_ERROR_MESSAGES.password_required))
    .label("Password"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], t(CUSTOM_ERROR_MESSAGES.password_match))
    .required(t(CUSTOM_ERROR_MESSAGES.password_required))
    .label("Password Confirmation")
});

    //Form submit function
    const onSubmitForgotPassword = async (values) => {

      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const userController = store.state.ControllersModule.userController;
      if (!userController) {
        throw new Error("userController is not available in Vuex store.");
      }

      try {
        const response = await userController.resetPassword({
          code: token,
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
        } as ResetPasswordModel);

        if (response.isSuccess) {
          swalNotification.success(
            t(SWAL_MESSAGES.SUCCESS_RESET_PASSWORD),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          router.push({ name: ROUTE_PAGES.SIGN_IN });
        } else {
          swalNotification.error(
            response.error.cause.cause +
              response.error +
              response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
        }
      } catch (e) {
        console.log(e);
        swalNotification.error(
          "vue error",
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    onMounted(() => {
    token = route.query.token?.toString();
  });

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
