<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      @submit="onSubmitForgotPassword"
      id="kt_login_password_reset_form"
      :validation-schema="forgotPassword"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">
          {{ $t("menuPages.resetPassword.pageName") }}
        </h1>
        <!--end::Title-->

        <!--begin::Link-->
        <div class="text-gray-400 fw-bold fs-4">
          {{ $t("menuPages.resetPassword.pageDescription") }}
        </div>
        <!--end::Link-->
      </div>
      <!--begin::Heading-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <label class="form-label fw-bolder text-gray-900 fs-6">{{
          $t("menuPages.resetPassword.newPassword")
        }}</label>
        <Field
          class="form-control form-control-solid"
          type="password"
          placeholder=""
          name="password"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="password" />
          </div>
        </div>
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <label class="form-label fw-bolder text-gray-900 fs-6">{{
          $t("menuPages.resetPassword.newPasswordAgain")
        }}</label>
        <Field
          class="form-control form-control-solid"
          type="password"
          placeholder=""
          name="passwordConfirmation"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="passwordConfirmation" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          ref="submitButton"
          id="kt_password_reset_submit"
          class="btn btn-lg btn-primary fw-bolder me-4"
        >
          <span class="indicator-label"> {{ $t("common.button.save") }} </span>
          <span class="indicator-progress">
            {{ $t("common.button.loader") }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { EXCEPTION_TYPE } from "@/core/Constant";
import { ResetPasswordModel } from "@/domain/authentication/resetPassword/model/ResetPasswordModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { CUSTOM_ERROR_MESSAGES, ROUTE_PAGES, SWAL_MESSAGES } from "@/domain/Constant";

export default defineComponent({
  name: "password-reset",
  props: {
    token: {
      type: String,
      required: true
    }
  },
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const swalNotification = new SwalNotification();
    const submitButton = ref<HTMLElement | null>(null);
      let token : undefined | string = undefined;

    //Create form validation object
    const forgotPassword = Yup.object().shape({
  password: Yup.string()
    .min(
      EXCEPTION_TYPE.MIN_PASSWORD_LENGTH,
      t(CUSTOM_ERROR_MESSAGES.password_min)
    )
    .required(t(CUSTOM_ERROR_MESSAGES.password_required))
    .label("Password"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], t(CUSTOM_ERROR_MESSAGES.password_match))
    .required(t(CUSTOM_ERROR_MESSAGES.password_required))
    .label("Password Confirmation")
});

    //Form submit function
    const onSubmitForgotPassword = async (values) => {

      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const userController = store.state.ControllersModule.userController;
      if (!userController) {
        throw new Error("userController is not available in Vuex store.");
      }

      try {
        const response = await userController.resetPassword({
          code: token,
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
        } as ResetPasswordModel);

        if (response.isSuccess) {
          swalNotification.success(
            t(SWAL_MESSAGES.SUCCESS_RESET_PASSWORD),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          router.push({ name: ROUTE_PAGES.SIGN_IN });
        } else {
          swalNotification.error(
            response.error.cause.cause +
              response.error +
              response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
        }
      } catch (e) {
        console.log(e);
        swalNotification.error(
          "vue error",
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    onMounted(() => {
    token = route.query.token?.toString();
  });

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
</script>
